<template>
    <div class="actions">
        <router-link class="btn btn-outline-secondary btn-rounded btn-sm" :to="{name: 'host', params: {uid: object.uid}}"><i class="icofont icofont-eye-alt icofont"></i></router-link>
        <a  href="javascript:void(0)" class="btn btn-outline-primary btn-rounded btn-sm"><i class="icofont icofont-pencil icofont"></i></a>
        <a  href="javascript:void(0)" class="btn btn-outline-danger btn-rounded btn-sm" @click.prevent="deleting"><i class="icofont icofont-close icofont"></i></a>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {DELETE_HOST} from '../../../graphql/host'
export default {
    props: {
        object: {type: Object, require: true}
    },
    data(){
        return {

        }
    },
    methods: {
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_HOST,
                variables: {
					uid: this.object.uid
				},
                update: (store, { data: model }) => {
                    console.log(`Delete of model ${model}`)
				},
            })
        }
    },
    computed:{
        ...mapGetters({
            is_partenaire: 'auth/is_partenaire'
        })
    }
}
</script>
<style lang="scss" scoped>
    .actions{
        a{
            margin-left: 5px !important;
        }
    }
    
</style>